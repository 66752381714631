.destination-list-body {
    max-width: 100%;
}

.destination-list-body .m-2 {
    width: calc(33.33% - 20px);
}

.destination-list-body {
    display: flex;
    flex-wrap: wrap;
}

.destination-list-body > .text-center {
    width: 100%;
    display: flex;
    font-size: 22px;
    margin-bottom: 30px;
    text-transform: uppercase;
    vertical-align: middle;
    align-items: center;
}

.destination-list-body > .text-center p {
    font-size: 22px;
    margin-right: 15px;
    vertical-align: middle;
}

.destination-list-body .m-2 figure {
    height: auto !important;
    width: auto !important;
    margin-right: 20px;
    margin-bottom: 0;
}

.destination-list-body .m-2 figure img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 1px solid #ccc;
    object-fit: cover;
}

.destination-list-body .m-2 .border {
    padding: 15px;
    background: #f2f2f2;
}

.destination-list-body .m-2 .border p {
    margin-bottom: 0;
}

.destination-list-body .m-2 .border .d-flex {
    align-items: center;
    margin-bottom: 20px;
}